<template>
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 0L15.298 2.28C15.5015 2.34354 15.6794 2.47048 15.8057 2.64229C15.932 2.81409 16.0001 3.02177 16 3.235V5H18C18.2652 5 18.5196 5.10536 18.7071 5.29289C18.8946 5.48043 19 5.73478 19 6V14C19 14.2652 18.8946 14.5196 18.7071 14.7071C18.5196 14.8946 18.2652 15 18 15L14.78 15.001C14.393 15.511 13.923 15.961 13.38 16.331L8 20L2.62 16.332C1.81252 15.7815 1.15175 15.042 0.69514 14.1779C0.238528 13.3138 -0.000101223 12.3513 3.22098e-08 11.374V3.235C0.000120606 3.02194 0.0682866 2.81449 0.194562 2.64289C0.320838 2.47128 0.498622 2.34449 0.702 2.281L8 0ZM8 2.094L2 3.97V11.374C1.99986 11.9862 2.14025 12.5903 2.41036 13.1397C2.68048 13.6892 3.07311 14.1692 3.558 14.543L3.747 14.679L8 17.58L11.782 15H7C6.73478 15 6.48043 14.8946 6.29289 14.7071C6.10536 14.5196 6 14.2652 6 14V6C6 5.73478 6.10536 5.48043 6.29289 5.29289C6.48043 5.10536 6.73478 5 7 5H14V3.97L8 2.094ZM8 10V13H17V10H8ZM8 8H17V7H8V8Z"
      fill="#8C8F96"
    />
  </svg>
</template>

<script>
export default {
  name: "GuardIcon",
};
</script>

<style scoped></style>
